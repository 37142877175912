$(document).on('ready turbolinks:load', function() {

  var toogleButton = function(target) {
    const buttonElement = document.getElementById(target+ '-button');
    const selectElement = document.getElementById(target+ '-select');
    if (selectElement != null) {
      if (selectElement.value === '') {
        buttonElement.disabled = true;
        $(buttonElement).addClass("disabled btn-nonactive");
      } else {
        buttonElement.disabled = false;
        $(buttonElement).removeClass("disabled btn-nonactive");
      }
    }
  }
  toogleButton('facility');
  toogleButton('tag');
  $("select[id='facility-select']").change(function() {
    toogleButton('facility');
  });
  $("select[id='tag-select']").change(function() {
    toogleButton('tag');
  });
});