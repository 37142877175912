$(document).on('turbolinks:load', function() {
  // プレビューボタンの data-disabled が独立して外せないので力技
  $('.card-footer .btn.preview').click(function () {
    setTimeout(function () {
      $('.card-footer .btn').prop('disabled', false);
    }, 1000);
  });

  $(".accordion-switch").each(function( index ) {
    var self = $( this );
    if (self.parent().find(".triangle-container").length === 0) {
      $( "<div class='triangle-container'><div class='rouned-triangle'/></div>" ).insertBefore(self);
    }
    
  });
  $("nav.pagination").each(function( index ) {
    var self = $( this );
    if (!self.parent().hasClass("pagy-nav-container")) {
      self.wrap($( "<div class='pagy-nav-container'/>" ));
    }
  });
  $('.accordion-switch').on('change', function(evt) {
    var self = $(this);
    var arrow = self.parent();
    arrow.toggleClass("opened", 1000);
    var roomInfo = self.closest(".row-table").find(".row-information");
    roomInfo.toggleClass("hidden", 1000);
    var media = roomInfo.find(".media-photo");
    var img = roomInfo.find(".slick-current").find("img");
    media.height(img.height());
  });

  if ($('.card-footer .btn-left').children().length <= 0) {
    $('.card-footer .btn-left').remove();
  } 

  if ($('.card-footer .btn-right').children().length <= 0) {
    $('.card-footer .btn-right').remove();
  } 

  var sidenav_change = function () {
    var sidenav_container_width = $(".navigate_menu_with_accordion").width();
    var sidenav = $(".navigate_menu_with_accordion .accordion_content .sidenav-list>li");
    sidenav.removeClass("last-row").removeClass("only-cell");
    if (sidenav_container_width <= 670) {
      if (sidenav.length % 2 === 1 ) {
        $(sidenav[sidenav.length - 1]).addClass("last-row only-cell");
      }
    } else if (sidenav_container_width <= 1099){
      if (sidenav.length % 3 === 2 ) {
        $(sidenav[sidenav.length - 1]).addClass("last-row");
        $(sidenav[sidenav.length - 2]).addClass("last-row");
      } else if (sidenav.length % 3 === 1) {
        $(sidenav[sidenav.length - 1]).addClass("last-row only-cell");
      }
    }
  }
  sidenav_change();
  $(window).on('resize', function(evt) {
    sidenav_change();
  });
});

